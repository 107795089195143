




























































































































import { Component, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { createCart, getPricesAddable } from '@/api/shop'
import VsSubscriptionCard from '@/modules/shop/components/VsSubscriptionCard/Index.vue'
import { getUserPlan } from '@/utils/shop'
import { UserModule } from '@/store/modules/user'
import VsComparePlansModal from '@/modules/shop/components/VsComparePlansModal/Index.vue'

@Component({
    name: 'Subscriptions',
    components: {
        VsLoader,
        VsSubscriptionCard,
        VsComparePlansModal,
    },
})
export default class extends Vue {
    selectedRange = 0
    annualRate = true
    productsAddable = []
    variant = ''

    monthlyCredits = [
        {
            label: '10k',
            value: '10000',
        },
        {
            label: '20k',
            value: '20000',
        },
        {
            label: '40k',
            value: '40000',
        },
        {
            label: '60k',
            value: '60000',
        },
        {
            label: '80k',
            value: '80000',
        },
        // {
        //     label: '125k',
        //     value: '125000',
        // },
        // {
        //     label: '175k',
        //     value: '175000',
        // },
        // {
        //     label: '250k',
        //     value: '250000',
        // },
    ]

    yearlyCredits = [
        {
            label: '144k',
            value: '144000',
        },
        {
            label: '264k',
            value: '264000',
        },
        {
            label: '504k',
            value: '504000',
        },
        {
            label: '840k',
            value: '840000',
        },
        {
            label: '1.2M',
            value: '1200000',
        },
        // {
        //     label: '1.5M',
        //     value: '1500000',
        // },
        // {
        //     label: '2.1M',
        //     value: '2100000',
        // },
        // {
        //     label: '3.0M',
        //     value: '3000000',
        // },
    ]

    get currentEmailRate () {
        return this.annualRate ? this.yearlyCredits[this.selectedRange].value : this.monthlyCredits[this.selectedRange].value
    }

    get shopUser () {
        return UserModule.shopUser
    }

    get user () {
        return UserModule.user
    }

    get userPlanPriceId () {
        const userPlan = getUserPlan(this.shopUser)
        if (!userPlan) return
        return userPlan.priceId || ''
    }

    public async beforeMount () {
        this.getPricesAddable()
    }

    private async getPricesAddable () {
        try {
            const resp = await getPricesAddable()
            this.productsAddable = resp.data
        } catch (e) {
            console.log(e)
            this.productsAddable = []
        }
    }

    private findSelectedPrice (slug: string) {
        return this.productsAddable.find((el: any) => {
            const rateType = this.annualRate ? 'years' : 'months'
            return el.product.metadata?.ruleGroup === slug && el.metadata.rateCredits === this.currentEmailRate && el.rate && el.rate.type === rateType
        })
    }

    private async createCart (event: any) {
        try {
            const priceId = event.priceId
            const resp = await createCart({
                items: [{
                    priceId,
                    quantity: 1,
                }],
            })
            this.$emit('cart-created', resp.data)
            this.trackCartCreatedMixpanelEvent(resp.data, 'PricePageSubscription', event.trial)
            if (event.trial) this.saveSessionStorage(resp.data.id)

            this.$router.push({
                name: 'cart',
            })
        } catch (e) {
            console.log(e)
        }
    }

    private trackCartCreatedMixpanelEvent (cart: any, sourceName: string, trial: boolean) {
        if (UserModule.Mixpanel) {
            UserModule.Mixpanel.track(
                'CartCreated',
                {
                    distinct_id: this.user._id,
                    CartId: cart._id,
                    Trial: trial,
                    HasSubscription: !!(this.shopUser && this.shopUser.subscription),
                    Interval: this.annualRate ? 'years' : 'months',
                    Source: sourceName,
                },
            )
        }
    }

    saveSessionStorage (idCart: string) {
        window.sessionStorage.setItem('trial-cart', JSON.stringify({
            idCart,
            trial: true,
        }))
    }
}
